import { render, staticRenderFns } from "./AnkeilerHalf.vue?vue&type=template&id=5ca50bc6&scoped=true"
import script from "./AnkeilerHalf.vue?vue&type=script&lang=js"
export * from "./AnkeilerHalf.vue?vue&type=script&lang=js"
import style0 from "./AnkeilerHalf.vue?vue&type=style&index=0&id=5ca50bc6&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ca50bc6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesVisual: require('/src/components/molecules/Visual.vue').default,AtomsLink: require('/src/components/atoms/Link.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,AtomsModuleWrapper: require('/src/components/atoms/ModuleWrapper.vue').default})
