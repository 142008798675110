import { render, staticRenderFns } from "./Ankeiler.vue?vue&type=template&id=fdb8795c&scoped=true"
import script from "./Ankeiler.vue?vue&type=script&lang=js"
export * from "./Ankeiler.vue?vue&type=script&lang=js"
import style0 from "./Ankeiler.vue?vue&type=style&index=0&id=fdb8795c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdb8795c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesVisual: require('/src/components/molecules/Visual.vue').default,MoleculesButtonPill: require('/src/components/molecules/button/Pill.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default})
