
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
import breakpoints from '~/constants/Breakpoints';
import { COLOR_SCHEME_YELLOW } from '~/constants/General';
import { customLinkFieldShape, imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    heading: string(),
    subheading: string(),
    hiringSteps: arrayOf(
      shape({
        id: string(),
        step: oneOfType([string(), nullable()]),
        heading: oneOfType([string(), nullable()]),
        body: oneOfType([string(), nullable()]),
        footerText: oneOfType([string(), nullable()]),
        visual: arrayOf(shape(imageShape).loose),
        boxed: bool().def(false),
      }).loose
    ),
    bannerHeading: oneOfType([string(), nullable()]),
    recruiterBannerHeading: oneOfType([string(), nullable()]),
    link: arrayOf(shape(customLinkFieldShape).loose),
  },
  data() {
    return {
      isMounted: false,
      COLOR_SCHEME_YELLOW,
    };
  },
  computed: {
    showSlider() {
      return this.isMounted && this.$store.state.viewport.width < breakpoints.s;
    },
    recruiterPageLink() {
      return [
        {
          type: 'internal',
          label: this.$t('Read more'),
          to: this.localePath('/faq'),
        },
      ];
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
