
import { arrayOf, nullable, number, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    title: string(),
    url: oneOfType([string(), nullable()]),
    images: arrayOf(shape(imageShape).loose).def([]),
    openings: number(),
    animationDelay: number().def(0),
  },
};
