import { render, staticRenderFns } from "./CareerCategory.vue?vue&type=template&id=4e060b40&scoped=true"
import script from "./CareerCategory.vue?vue&type=script&lang=js"
export * from "./CareerCategory.vue?vue&type=script&lang=js"
import style0 from "./CareerCategory.vue?vue&type=style&index=0&id=4e060b40&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e060b40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesImageSlideShow: require('/src/components/molecules/ImageSlideShow.vue').default,MoleculesIconWithBackslash: require('/src/components/molecules/IconWithBackslash.vue').default})
