
import { nullable, oneOfType, string } from 'vue-types';
export default {
  inheritAttrs: false,
  props: {
    title: string(),
    body: oneOfType([string(), nullable()]),
    subtitle: oneOfType([string(), nullable()]),
  },
  methods: {
    openOpeningsOverlay() {
      this.$store.commit('overlays/openings', {
        active: true,
      });
    },
  },
};
