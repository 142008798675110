
import { arrayOf, nullable, object, oneOfType, shape, string } from 'vue-types';
import {
  COLOR_SCHEME_WHITE,
  COLOR_SCHEME_YELLOW,
  COLOR_SCHEME_TRANSPARENT,
} from '~/constants/General';
import { categoryShape, imageShape } from '~/constants/PropTypes';
import navColorDetection from '@/mixins/nav-color-detection';

export default {
  mixins: [navColorDetection],
  inheritAttrs: false,
  props: {
    title: string(),
    subtitle: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    hiringSteps: arrayOf(object()),
    ankeiler: arrayOf(object()),
    ankeilerHalf: arrayOf(object()),
    careerCategories: arrayOf(
      shape({
        ...categoryShape,
        image: arrayOf(shape(imageShape).loose),
      }).loose
    ).def([]),
    thanksForApplying: object(),
  },
  data() {
    return {
      COLOR_SCHEME_YELLOW,
      COLOR_SCHEME_WHITE,
      COLOR_SCHEME_TRANSPARENT,
    };
  },
  computed: {
    careers() {
      return this.$store.state.openings || [];
    },
    categories() {
      return this.careerCategories.map((category) => {
        const careers = this.careers.filter(({ category: cat }) =>
          cat.some(({ id }) => id === category.id)
        );
        return {
          title: category.title,
          url: category.url,
          images: category.images,
          openings: careers.length,
        };
      });
    },
    recruiterPageLink() {
      return [
        {
          type: 'internal',
          label: this.$t('career.recruiter.buttonLabel'),
          to: this.$i18n.locale === 'en' ? '/en/faq' : '/nl/faq',
        },
      ];
    },
  },
};
