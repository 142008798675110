
import {
  arrayOf,
  bool,
  nullable,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { COLOR_SCHEME_BLUE, COLOR_SCHEME_YELLOW } from '~/constants/General';
import { customLinkFieldShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    link: arrayOf(shape(customLinkFieldShape).loose),
    scrollToSubscribe: bool().def(false),
    colorScheme: oneOf([COLOR_SCHEME_YELLOW, COLOR_SCHEME_BLUE]).def(
      COLOR_SCHEME_BLUE
    ),
  },
  computed: {
    isYellow() {
      return this.colorScheme === COLOR_SCHEME_YELLOW;
    },
  },
  methods: {
    onButtonClick(e) {
      /**
       * @TODO Rrefactor this into something nice
       */

      e.preventDefault();
      const subscribeSection = document.querySelector('#subscribe-section');

      if (subscribeSection) {
        const elTop =
          subscribeSection.getBoundingClientRect().top + window.scrollY;
        const scrollTo =
          elTop - (window.innerHeight - subscribeSection.offsetHeight) / 2;
        window.scroll({
          top: scrollTo,
          left: 0,
          behavior: 'smooth',
        });
      }
    },
  },
};
