
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    visual: arrayOf(shape(imageShape).loose),
    step: oneOfType([string(), nullable()]),
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    footerText: oneOfType([string(), nullable()]),
    optional: bool().def(false),
  },
};
