
import { arrayOf, number, shape } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';

const ANIMATION_DURATION = 3000;

export default {
  props: {
    images: arrayOf(shape(imageShape).loose).def([]),
    delay: number().def(0),
  },
  data() {
    return {
      activeIndex: -1,
    };
  },
  mounted() {
    this.startAnimation();
  },
  methods: {
    startAnimation() {
      this.activeIndex = 0;
      setTimeout(() => {
        setInterval(() => {
          this.activeIndex = (this.activeIndex + 1) % this.images.length;
        }, ANIMATION_DURATION);
      }, this.delay);
    },
  },
};
