/**
 * For now, this mixin is only used on the career overview, career category and career detail pages.
 * This logic is created in a different way for other pages but in a less performant and reusable way (check the module mixin).
 *
 * This logic must be applied to all pages, when there is time.
 *
 * - Niels
 */

export default {
  mounted() {
    this.$nextTick(() => {
      setTimeout(this.setNavColorPositions, 1000);
    });
  },
  methods: {
    setNavColorPositions() {
      const elements = [...document.querySelectorAll('[data-nav-color]')];

      const positions = [
        {
          top: 0,
          colorScheme: this.$store.state.nav.colorScheme,
        },
        ...elements.map((el) => ({
          top: el.getBoundingClientRect().top + window.scrollY,
          colorScheme: el.dataset.navColor,
        })),
      ];

      this.$store.commit('nav/pageColorPositions', positions);
    },
  },
};
