
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
import { SIZE_LARGE } from '~/constants/General';
import { customLinkFieldShape, imageShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    visuals: arrayOf(shape(imageShape).loose),
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    link: arrayOf(shape(customLinkFieldShape).loose),
    rtl: bool().def(false),
  },
  data() {
    return {
      SIZE_LARGE,
    };
  },
};
