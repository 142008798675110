
import { arrayOf, nullable, number, oneOfType, shape, string } from 'vue-types';
import { imageShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    categories: arrayOf(
      shape({
        title: string(),
        url: oneOfType([string(), nullable()]),
        images: arrayOf(shape(imageShape).loose),
        openings: number(),
      }).loose
    ),
  },
};
