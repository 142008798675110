
import { arrayOf, bool, nullable, oneOfType, shape, string } from 'vue-types';
import { COLOR_SCHEME_TRANSPARENT, SIZE_MEDIUM } from '~/constants/General';
import { customLinkFieldShape, imageShape } from '~/constants/PropTypes';

export const ankeilerProps = {
  heading: oneOfType([string(), nullable()]),
  body: oneOfType([string(), nullable()]),
  link: arrayOf(shape(customLinkFieldShape).loose),
  visual: arrayOf(shape(imageShape).loose),
};

export default {
  inheritAttrs: false,
  props: {
    prevBg: oneOfType([string(), nullable()]),
    nextBg: oneOfType([string(), nullable()]),
    reverse: bool().def(false),
    ...ankeilerProps,
  },
  data() {
    return {
      SIZE_MEDIUM,
      COLOR_SCHEME_TRANSPARENT,
    };
  },
};
